<template>
        <ul class="sidebar-sub-menu ps-0 mb-0 list-unstyled" id="menu-patients">
            
            <li 
                class="sidebar-sub-menu-item"
                v-for="list in lists" :key="list.uuid">
                <router-link
                    :to="'/dashboard?report='+`${list.id}`"
                    class="sidebar-sub-menu-link">

                    {{ list.name }}
                
                </router-link>
                
            </li>
            
          </ul>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import axios from "axios";
import configs from "@/utils/configs";
import onSystem from "@/utils/getSystemOn";

const parserPatient = (patient: any) => {
  return (({ id, name, professional }) => ({ id, name, professional }))(patient);
};

const patients = ref([]) as any;  // Armazena os dados dos pacientes
const loadPatientJsons = async ()=>{
  let folderBucket = await onSystem();

  const response = await axios.get(configs.urlApiFiles+'/api/v1/list-files?folder='+folderBucket+'/CMJ5');
 
  let cleanJsonFiles = response.data.map((file:any) => {
    let uuidMatch = file.split('__')[1];
    uuidMatch = uuidMatch.replace('.json', '');
    // Se encontrar o UUID, retornar o UUID seguido de .json
    return uuidMatch;
  });

  //gravar a versao da lista do bucket S3
  localStorage.setItem('patients_bucket_json', '');
  localStorage.setItem('patients_bucket_json', JSON.stringify(response.data));

  localStorage.setItem('patients_jsons', '');
  localStorage.setItem('patients_jsons', JSON.stringify(cleanJsonFiles));
}
const fetchPatientsApi = async () => {
  const loading = ref(true);
  const error = ref(null);
  try {
    
    const response = await axios.get(configs.urlApiFiles+'/api/v1/patients')

    // Armazena o array "results" dos pacientes na constante reativa
    patients.value = response.data.results;
        

    let assessments:string | any = localStorage.getItem('on_assessments');
      let system:string | any = localStorage.getItem('on_system');
      if(assessments){
        assessments = JSON.parse(atob(assessments));
        system = JSON.parse(atob(system));
        //varrer todos os IDs de listPatients.uuid relacionados a um assessments.patient
        
        let uniquePatients = assessments.reduce((acc, patient) => {
          // Verifica se o paciente já está no array acumulado
          if (!acc.some(p => p.patient === patient.patient)) {
            acc.push(patient);
          }
          return acc;
        }, []);
        let pacientsIds:any = [];
        uniquePatients.forEach((access:any) => {
          //versao para desenvolvimento
            //if(/* access.system === system.id || */ access.system === "dfcc87e4-51c1-43b2-9fec-c5ffe227e369"){
          //versao para subir ao servidor
            if( access.system === system.id ){
              
              if(access.patient){
                pacientsIds.push(access.patient);
              }
              
            }
            
        });
        
        //filtrar o menu para apenas os pacientes do system trabalhado
        let filteredPatients = patients.value.filter((patient:any) => pacientsIds.includes(patient.id));
        
        
        filteredPatients.sort((a:any, b:any) => a.name.localeCompare(b.name));
        //rendenrizando o menu
        patients.value = filteredPatients;
      }
    
    
  } catch (err: any) {
    // Armazena o erro na constante reativa
    error.value = err.message || 'Erro ao buscar pacientes'
  } finally {
    // Finaliza o estado de carregamento
    loading.value = false
  }
}

export default defineComponent({
  name: "PatientsMenu",
  data() {
    return {
      lists: patients,
      patientsResults: {} as any,
      objPatientsApi: [] as any,
    };
  },
  mounted(){
    fetchPatientsApi();
    this.showPatientsRelational();
    //consulta a API JSON FILES e ver quais UUIDS estao cadastrados
    loadPatientJsons();
    
  },
  methods: {
    
    showPatientsRelational(){
      let assessments:string | any = localStorage.getItem('on_assessments');
      let system:string | any = localStorage.getItem('on_system');
      if(assessments){
        assessments = JSON.parse(atob(assessments));
        system = JSON.parse(atob(system));
        //varrer todos os IDs de listPatients.uuid relacionados a um assessments.patient
        
        let uniquePatients = assessments.reduce((acc, patient) => {
          // Verifica se o paciente já está no array acumulado
          if (!acc.some(p => p.patient === patient.patient)) {
            acc.push(patient);
          }
          return acc;
        }, []);
        let pacientsIds:any = [];
        uniquePatients.forEach((access:any) => {
          //versao para desenvolvimento
            //if(/* access.system === system.id || */ access.system === "dfcc87e4-51c1-43b2-9fec-c5ffe227e369"){
          //versao para subir ao servidor
            if( access.system === system.id ){
              
              if(access.patient){
                pacientsIds.push(access.patient);
              }
              
            }
            
        });
        
        //filtrar o menu para apenas os pacientes do system trabalhado
        let filteredPatients = patients.value.filter((patient:any) => pacientsIds.includes(patient.id));
        
        
        filteredPatients.sort((a:any, b:any) => a.name.localeCompare(b.name));
        //rendenrizando o menu
        this.lists= filteredPatients;
      }
    },
  },
  
  
});
</script>
<style scoped>
li .sidebar-sub-menu-item, a{
  padding-bottom: 0px;
  padding-top: 0px;
}
</style>
