import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-1a41bf26"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 0,
  class: "row"
}
const _hoisted_4 = { class: "col-xxl-12 col-sm-12" }
const _hoisted_5 = { class: "card mb-20 border-0 rounded-0 stats-item bg-white" }
const _hoisted_6 = { class: "card-body p-0 p-sm-0 p-md-0 p-lg-0 letter-spacing" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = ["src"]
const _hoisted_9 = {
  key: 1,
  class: "d-flex align-items-center"
}
const _hoisted_10 = { class: "d-block text-muted fs-12 fw-medium p-2 p-sm-2 p-md-2 p-lg-2" }
const _hoisted_11 = { class: "d-block text-muted fs-12 fw-medium p-2 p-sm-2 p-md-2 p-lg-2" }
const _hoisted_12 = { class: "d-block text-muted fs-12 fw-medium p-2 p-sm-2 p-md-2 p-lg-2" }
const _hoisted_13 = { class: "d-block text-muted fs-12 fw-medium p-2 p-sm-2 p-md-2 p-lg-2" }
const _hoisted_14 = { class: "d-block text-muted fs-12 fw-medium p-2 p-sm-2 p-md-2 p-lg-2" }
const _hoisted_15 = { class: "d-block text-muted fs-12 fw-medium p-2 p-sm-2 p-md-2 p-lg-2" }
const _hoisted_16 = { class: "d-block text-muted fs-12 fw-medium p-2 p-sm-2 p-md-2 p-lg-2" }
const _hoisted_17 = { class: "d-block text-muted fs-12 fw-medium p-2 p-sm-2 p-md-2 p-lg-2" }
const _hoisted_18 = { class: "mb-20 d-sm-flex align-items-center justify-content-between letter-spacing" }
const _hoisted_19 = { class: "mb-5 mb-sm-0 fw-bold" }
const _hoisted_20 = { class: "mb-20 d-sm-flex align-items-center justify-content-between letter-spacing" }
const _hoisted_21 = { class: "mb-5 mb-sm-0 fw-bold" }
const _hoisted_22 = { class: "row" }
const _hoisted_23 = { class: "col-xl-3 col-sm-6" }
const _hoisted_24 = { class: "card mb-25 border-0 rounded-0 bg-white stats-box shadow-link" }
const _hoisted_25 = { class: "card-body pe-20 ps-20 pe-md-25 ps-md-25 pe-lg-15 ps-lg-15" }
const _hoisted_26 = { class: "d-flex align-items-center" }
const _hoisted_27 = { class: "title ms-15" }
const _hoisted_28 = { class: "d-block mb-7 fs-13 text-uppercase fw-medium text-dark-emphasis" }
const _hoisted_29 = { class: "d-flex align-items-center" }
const _hoisted_30 = { class: "fw-black mb-0 me-10" }
const _hoisted_31 = ["innerHTML"]
const _hoisted_32 = { class: "col-xl-3 col-sm-6" }
const _hoisted_33 = { class: "card mb-25 border-0 rounded-0 bg-white stats-box shadow-link" }
const _hoisted_34 = { class: "card-body pe-20 ps-20 pe-md-25 ps-md-25 pe-lg-15 ps-lg-15" }
const _hoisted_35 = { class: "d-flex align-items-center" }
const _hoisted_36 = { class: "title ms-15" }
const _hoisted_37 = { class: "d-block mb-7 fs-13 text-uppercase fw-medium text-dark-emphasis" }
const _hoisted_38 = { class: "d-flex align-items-center" }
const _hoisted_39 = { class: "fw-black mb-0 me-10" }
const _hoisted_40 = ["innerHTML"]
const _hoisted_41 = { class: "col-xl-3 col-sm-6" }
const _hoisted_42 = { class: "card mb-25 border-0 rounded-0 bg-white stats-box shadow-link" }
const _hoisted_43 = { class: "card-body pe-20 ps-20 pe-md-25 ps-md-25 pe-lg-15 ps-lg-15" }
const _hoisted_44 = { class: "d-flex align-items-center" }
const _hoisted_45 = { class: "title ms-15" }
const _hoisted_46 = { class: "d-block mb-7 fs-13 text-uppercase fw-medium text-dark-emphasis" }
const _hoisted_47 = { class: "d-flex align-items-center" }
const _hoisted_48 = { class: "fw-black mb-0 me-10" }
const _hoisted_49 = ["innerHTML"]
const _hoisted_50 = { class: "col-xl-3 col-sm-6" }
const _hoisted_51 = { class: "card mb-25 border-0 rounded-0 bg-white stats-box shadow-link" }
const _hoisted_52 = { class: "card-body pe-20 ps-20 pe-md-25 ps-md-25 pe-lg-15 ps-lg-15" }
const _hoisted_53 = { class: "d-flex align-items-center" }
const _hoisted_54 = { class: "title ms-15" }
const _hoisted_55 = { class: "d-block mb-7 fs-13 text-uppercase fw-medium text-dark-emphasis" }
const _hoisted_56 = { class: "d-flex align-items-center" }
const _hoisted_57 = { class: "fw-black mb-0 me-10" }
const _hoisted_58 = ["innerHTML"]
const _hoisted_59 = { class: "row" }
const _hoisted_60 = { class: "col-xl-3 col-sm-6" }
const _hoisted_61 = { class: "card mb-25 border-0 rounded-0 bg-white stats-box shadow-link" }
const _hoisted_62 = { class: "card-body pe-20 ps-20 pe-md-25 ps-md-25 pe-lg-15 ps-lg-15" }
const _hoisted_63 = { class: "d-flex align-items-center" }
const _hoisted_64 = { class: "title ms-15" }
const _hoisted_65 = { class: "d-block mb-7 fs-13 text-uppercase fw-medium text-dark-emphasis" }
const _hoisted_66 = { class: "d-flex align-items-center" }
const _hoisted_67 = { class: "fw-black mb-0 me-10" }
const _hoisted_68 = ["innerHTML"]
const _hoisted_69 = { class: "col-xl-3 col-sm-6" }
const _hoisted_70 = { class: "card mb-25 border-0 rounded-0 bg-white stats-box shadow-link" }
const _hoisted_71 = { class: "card-body pe-20 ps-20 pe-md-25 ps-md-25 pe-lg-15 ps-lg-15" }
const _hoisted_72 = { class: "d-flex align-items-center" }
const _hoisted_73 = { class: "title ms-15" }
const _hoisted_74 = { class: "d-block mb-7 fs-13 text-uppercase fw-medium text-dark-emphasis" }
const _hoisted_75 = { class: "d-flex align-items-center" }
const _hoisted_76 = { class: "fw-black mb-0 me-10" }
const _hoisted_77 = ["innerHTML"]
const _hoisted_78 = { class: "col-xl-3 col-sm-6" }
const _hoisted_79 = { class: "card mb-25 border-0 rounded-0 bg-white stats-box shadow-link" }
const _hoisted_80 = { class: "card-body pe-20 ps-20 pe-md-25 ps-md-25 pe-lg-15 ps-lg-15" }
const _hoisted_81 = { class: "d-flex align-items-center" }
const _hoisted_82 = { class: "title ms-15" }
const _hoisted_83 = { class: "d-block mb-7 fs-13 text-uppercase fw-medium text-dark-emphasis" }
const _hoisted_84 = { class: "d-flex align-items-center" }
const _hoisted_85 = { class: "fw-black mb-0 me-10" }
const _hoisted_86 = ["innerHTML"]
const _hoisted_87 = { class: "mb-20 d-sm-flex align-items-center justify-content-between letter-spacing" }
const _hoisted_88 = { class: "mb-5 mb-sm-0 fw-bold" }
const _hoisted_89 = { class: "mb-20 d-sm-flex align-items-center justify-content-between letter-spacing" }
const _hoisted_90 = { class: "mb-5 mb-sm-0 fw-bold" }
const _hoisted_91 = { class: "row" }
const _hoisted_92 = { class: "col-xl-4 col-sm-6" }
const _hoisted_93 = { class: "card mb-25 border-0 rounded-0 bg-white stats-box shadow-link" }
const _hoisted_94 = { class: "card-body pe-20 ps-20 pe-md-25 ps-md-25 pe-lg-15 ps-lg-15" }
const _hoisted_95 = { class: "d-flex align-items-center" }
const _hoisted_96 = { class: "title ms-15" }
const _hoisted_97 = { class: "d-block mb-7 fs-13 text-uppercase fw-medium text-dark-emphasis" }
const _hoisted_98 = { class: "d-flex align-items-center" }
const _hoisted_99 = {
  class: "w-black mb-0 me-10",
  style: {"padding-right":"17px"}
}
const _hoisted_100 = { class: "fw-black mb-0 me-10" }
const _hoisted_101 = { class: "d-flex align-items-center" }
const _hoisted_102 = { class: "w-black mb-0 me-10" }
const _hoisted_103 = ["innerHTML"]
const _hoisted_104 = { class: "fw-black mb-0 me-10" }
const _hoisted_105 = { class: "col-xl-4 col-sm-6" }
const _hoisted_106 = { class: "card mb-25 border-0 rounded-0 bg-white stats-box shadow-link" }
const _hoisted_107 = { class: "card-body pe-20 ps-20 pe-md-25 ps-md-25 pe-lg-15 ps-lg-15" }
const _hoisted_108 = { class: "d-flex align-items-center" }
const _hoisted_109 = { class: "title ms-15" }
const _hoisted_110 = { class: "d-block mb-7 fs-13 text-uppercase fw-medium text-dark-emphasis" }
const _hoisted_111 = { class: "d-flex align-items-center" }
const _hoisted_112 = {
  class: "w-black mb-0 me-10",
  style: {"padding-right":"17px"}
}
const _hoisted_113 = { class: "fw-black mb-0 me-10" }
const _hoisted_114 = { class: "d-flex align-items-center" }
const _hoisted_115 = { class: "w-black mb-0 me-10" }
const _hoisted_116 = ["innerHTML"]
const _hoisted_117 = { class: "fw-black mb-0 me-10" }
const _hoisted_118 = { class: "col-xl-4 col-sm-6" }
const _hoisted_119 = { class: "card mb-25 border-0 rounded-0 bg-white stats-box shadow-link" }
const _hoisted_120 = { class: "card-body pe-20 ps-20 pe-md-25 ps-md-25 pe-lg-15 ps-lg-15" }
const _hoisted_121 = { class: "d-flex align-items-center" }
const _hoisted_122 = { class: "title ms-15" }
const _hoisted_123 = { class: "d-block mb-7 fs-13 text-uppercase fw-medium text-dark-emphasis" }
const _hoisted_124 = { class: "d-flex align-items-center" }
const _hoisted_125 = {
  class: "w-black mb-0 me-10",
  style: {"padding-right":"17px"}
}
const _hoisted_126 = { class: "fw-black mb-0 me-10" }
const _hoisted_127 = { class: "d-flex align-items-center" }
const _hoisted_128 = { class: "w-black mb-0 me-10" }
const _hoisted_129 = ["innerHTML"]
const _hoisted_130 = { class: "fw-black mb-0 me-10" }
const _hoisted_131 = { class: "row" }
const _hoisted_132 = { class: "col-xl-4 col-sm-6" }
const _hoisted_133 = { class: "card mb-25 border-0 rounded-0 bg-white stats-box shadow-link" }
const _hoisted_134 = { class: "card-body pe-20 ps-20 pe-md-25 ps-md-25 pe-lg-15 ps-lg-15" }
const _hoisted_135 = { class: "d-flex align-items-center" }
const _hoisted_136 = { class: "title ms-15" }
const _hoisted_137 = { class: "d-block mb-7 fs-13 text-uppercase fw-medium text-dark-emphasis" }
const _hoisted_138 = { class: "d-flex align-items-center" }
const _hoisted_139 = {
  class: "w-black mb-0 me-10",
  style: {"padding-right":"17px"}
}
const _hoisted_140 = { class: "fw-black mb-0 me-10" }
const _hoisted_141 = { class: "d-flex align-items-center" }
const _hoisted_142 = { class: "w-black mb-0 me-10" }
const _hoisted_143 = ["innerHTML"]
const _hoisted_144 = { class: "fw-black mb-0 me-10" }
const _hoisted_145 = { class: "col-xl-4 col-sm-6" }
const _hoisted_146 = { class: "card mb-25 border-0 rounded-0 bg-white stats-box shadow-link" }
const _hoisted_147 = { class: "card-body pe-20 ps-20 pe-md-25 ps-md-25 pe-lg-15 ps-lg-15" }
const _hoisted_148 = { class: "d-flex align-items-center" }
const _hoisted_149 = { class: "title ms-15" }
const _hoisted_150 = { class: "d-block mb-7 fs-13 text-uppercase fw-medium text-dark-emphasis" }
const _hoisted_151 = { class: "d-flex align-items-center" }
const _hoisted_152 = {
  class: "w-black mb-0 me-10",
  style: {"padding-right":"17px"}
}
const _hoisted_153 = { class: "fw-black mb-0 me-10" }
const _hoisted_154 = { class: "d-flex align-items-center" }
const _hoisted_155 = { class: "w-black mb-0 me-10" }
const _hoisted_156 = ["innerHTML"]
const _hoisted_157 = { class: "fw-black mb-0 me-10" }
const _hoisted_158 = { class: "col-xl-4 col-sm-6" }
const _hoisted_159 = { class: "card mb-25 border-0 rounded-0 bg-white stats-box shadow-link" }
const _hoisted_160 = { class: "card-body pe-20 ps-20 pe-md-25 ps-md-25 pe-lg-15 ps-lg-15" }
const _hoisted_161 = { class: "d-flex align-items-center" }
const _hoisted_162 = { class: "title ms-15" }
const _hoisted_163 = { class: "d-block mb-7 fs-13 text-uppercase fw-medium text-dark-emphasis" }
const _hoisted_164 = { class: "d-flex align-items-center" }
const _hoisted_165 = {
  class: "w-black mb-0 me-10",
  style: {"padding-right":"17px"}
}
const _hoisted_166 = { class: "fw-black mb-0 me-10" }
const _hoisted_167 = ["innerHTML"]
const _hoisted_168 = { class: "d-flex align-items-center" }
const _hoisted_169 = { class: "w-black mb-0 me-10" }
const _hoisted_170 = ["innerHTML"]
const _hoisted_171 = { class: "fw-black mb-0 me-10" }
const _hoisted_172 = ["innerHTML"]
const _hoisted_173 = { class: "row" }
const _hoisted_174 = { class: "col-xl-4 col-sm-6" }
const _hoisted_175 = { class: "card mb-10 border-0 rounded-0 bg-white stats-box shadow-link" }
const _hoisted_176 = { class: "card-body pe-20 ps-20 pe-md-25 ps-md-25 pe-lg-15 ps-lg-15" }
const _hoisted_177 = { class: "d-flex align-items-center" }
const _hoisted_178 = { class: "title ms-15" }
const _hoisted_179 = { class: "d-block mb-7 fs-13 text-uppercase fw-medium text-dark-emphasis" }
const _hoisted_180 = { class: "d-flex align-items-center" }
const _hoisted_181 = {
  class: "w-black mb-0 me-10",
  style: {"padding-right":"17px"}
}
const _hoisted_182 = { class: "fw-black mb-0 me-10" }
const _hoisted_183 = { class: "d-flex align-items-center" }
const _hoisted_184 = { class: "w-black mb-0 me-10" }
const _hoisted_185 = ["innerHTML"]
const _hoisted_186 = { class: "fw-black mb-0 me-10" }
const _hoisted_187 = { class: "col-xl-4 col-sm-6" }
const _hoisted_188 = { class: "card mb-25 border-0 rounded-0 bg-white stats-box shadow-link" }
const _hoisted_189 = { class: "card-body pe-20 ps-20 pe-md-25 ps-md-25 pe-lg-15 ps-lg-15" }
const _hoisted_190 = { class: "d-flex align-items-center" }
const _hoisted_191 = { class: "title ms-15" }
const _hoisted_192 = { class: "d-block mb-7 fs-13 text-uppercase fw-medium text-dark-emphasis" }
const _hoisted_193 = { class: "d-flex align-items-center" }
const _hoisted_194 = {
  class: "w-black mb-0 me-10",
  style: {"padding-right":"17px"}
}
const _hoisted_195 = { class: "fw-black mb-0 me-10" }
const _hoisted_196 = { class: "d-flex align-items-center" }
const _hoisted_197 = { class: "w-black mb-0 me-10" }
const _hoisted_198 = ["innerHTML"]
const _hoisted_199 = { class: "fw-black mb-0 me-10" }
const _hoisted_200 = { class: "mb-10 d-sm-flex align-items-center justify-content-between letter-spacing" }
const _hoisted_201 = { class: "mb-5 mb-sm-0 fw-bold" }
const _hoisted_202 = { class: "row" }
const _hoisted_203 = { class: "col-xl-4 col-sm-6" }
const _hoisted_204 = { class: "card mb-25 border-0 rounded-0 bg-white stats-box shadow-link" }
const _hoisted_205 = { class: "card-body pe-20 ps-20 pe-md-25 ps-md-25 pe-lg-15 ps-lg-15" }
const _hoisted_206 = { class: "d-flex align-items-center" }
const _hoisted_207 = { class: "title ms-15" }
const _hoisted_208 = { class: "d-block mb-7 fs-13 text-uppercase fw-medium text-dark-emphasis" }
const _hoisted_209 = { class: "d-flex align-items-center" }
const _hoisted_210 = {
  class: "w-black mb-0 me-10",
  style: {"padding-right":"17px"}
}
const _hoisted_211 = { class: "fw-black mb-0 me-10" }
const _hoisted_212 = { class: "d-flex align-items-center" }
const _hoisted_213 = { class: "w-black mb-0 me-10" }
const _hoisted_214 = ["innerHTML"]
const _hoisted_215 = { class: "fw-black mb-0 me-10" }
const _hoisted_216 = { class: "col-xl-4 col-sm-6" }
const _hoisted_217 = { class: "card mb-25 border-0 rounded-0 bg-white stats-box shadow-link" }
const _hoisted_218 = { class: "card-body pe-20 ps-20 pe-md-25 ps-md-25 pe-lg-15 ps-lg-15" }
const _hoisted_219 = { class: "d-flex align-items-center" }
const _hoisted_220 = { class: "title ms-15" }
const _hoisted_221 = { class: "d-block mb-7 fs-13 text-uppercase fw-medium text-dark-emphasis" }
const _hoisted_222 = { class: "d-flex align-items-center" }
const _hoisted_223 = {
  class: "w-black mb-0 me-10",
  style: {"padding-right":"17px"}
}
const _hoisted_224 = { class: "fw-black mb-0 me-10" }
const _hoisted_225 = { class: "d-flex align-items-center" }
const _hoisted_226 = { class: "w-black mb-0 me-10" }
const _hoisted_227 = ["innerHTML"]
const _hoisted_228 = { class: "fw-black mb-0 me-10" }
const _hoisted_229 = { class: "col-xl-4 col-sm-6" }
const _hoisted_230 = { class: "card mb-25 border-0 rounded-0 bg-white stats-box shadow-link" }
const _hoisted_231 = { class: "card-body pe-20 ps-20 pe-md-25 ps-md-25 pe-lg-15 ps-lg-15" }
const _hoisted_232 = { class: "d-flex align-items-center" }
const _hoisted_233 = { class: "title ms-15" }
const _hoisted_234 = { class: "d-block mb-7 fs-13 text-uppercase fw-medium text-dark-emphasis" }
const _hoisted_235 = { class: "d-flex align-items-center" }
const _hoisted_236 = {
  class: "w-black mb-0 me-10",
  style: {"padding-right":"17px"}
}
const _hoisted_237 = { class: "fw-black mb-0 me-10" }
const _hoisted_238 = { class: "d-flex align-items-center" }
const _hoisted_239 = { class: "w-black mb-0 me-10" }
const _hoisted_240 = ["innerHTML"]
const _hoisted_241 = { class: "fw-black mb-0 me-10" }
const _hoisted_242 = { class: "row" }
const _hoisted_243 = { class: "col-xl-4 col-sm-6" }
const _hoisted_244 = { class: "card mb-25 border-0 rounded-0 bg-white stats-box shadow-link" }
const _hoisted_245 = { class: "card-body pe-20 ps-20 pe-md-25 ps-md-25 pe-lg-15 ps-lg-15" }
const _hoisted_246 = { class: "d-flex align-items-center" }
const _hoisted_247 = { class: "title ms-15" }
const _hoisted_248 = { class: "d-block mb-7 fs-13 text-uppercase fw-medium text-dark-emphasis" }
const _hoisted_249 = { class: "d-flex align-items-center" }
const _hoisted_250 = {
  class: "w-black mb-0 me-10",
  style: {"padding-right":"17px"}
}
const _hoisted_251 = { class: "fw-black mb-0 me-10" }
const _hoisted_252 = { class: "d-flex align-items-center" }
const _hoisted_253 = { class: "w-black mb-0 me-10" }
const _hoisted_254 = ["innerHTML"]
const _hoisted_255 = { class: "fw-black mb-0 me-10" }
const _hoisted_256 = { class: "col-xl-4 col-sm-6" }
const _hoisted_257 = { class: "card mb-25 border-0 rounded-0 bg-white stats-box shadow-link" }
const _hoisted_258 = { class: "card-body pe-20 ps-20 pe-md-25 ps-md-25 pe-lg-15 ps-lg-15" }
const _hoisted_259 = { class: "d-flex align-items-center" }
const _hoisted_260 = { class: "title ms-15" }
const _hoisted_261 = { class: "d-block mb-7 fs-13 text-uppercase fw-medium text-dark-emphasis" }
const _hoisted_262 = { class: "d-flex align-items-center" }
const _hoisted_263 = {
  class: "w-black mb-0 me-10",
  style: {"padding-right":"17px"}
}
const _hoisted_264 = { class: "fw-black mb-0 me-10" }
const _hoisted_265 = { class: "d-flex align-items-center" }
const _hoisted_266 = { class: "w-black mb-0 me-10" }
const _hoisted_267 = ["innerHTML"]
const _hoisted_268 = { class: "fw-black mb-0 me-10" }
const _hoisted_269 = { class: "col-xl-4 col-sm-6" }
const _hoisted_270 = { class: "card mb-25 border-0 rounded-0 bg-white stats-box shadow-link" }
const _hoisted_271 = { class: "card-body pe-20 ps-20 pe-md-25 ps-md-25 pe-lg-15 ps-lg-15" }
const _hoisted_272 = { class: "d-flex align-items-center" }
const _hoisted_273 = { class: "title ms-15" }
const _hoisted_274 = { class: "d-block mb-7 fs-13 text-uppercase fw-medium text-dark-emphasis" }
const _hoisted_275 = { class: "d-flex align-items-center" }
const _hoisted_276 = {
  class: "w-black mb-0 me-10",
  style: {"padding-right":"17px"}
}
const _hoisted_277 = { class: "fw-black mb-0 me-10" }
const _hoisted_278 = ["innerHTML"]
const _hoisted_279 = { class: "d-flex align-items-center" }
const _hoisted_280 = { class: "w-black mb-0 me-10" }
const _hoisted_281 = ["innerHTML"]
const _hoisted_282 = { class: "fw-black mb-0 me-10" }
const _hoisted_283 = ["innerHTML"]
const _hoisted_284 = { class: "row" }
const _hoisted_285 = { class: "col-xl-4 col-sm-6" }
const _hoisted_286 = { class: "card mb-25 border-0 rounded-0 bg-white stats-box shadow-link" }
const _hoisted_287 = { class: "card-body pe-20 ps-20 pe-md-25 ps-md-25 pe-lg-15 ps-lg-15" }
const _hoisted_288 = { class: "d-flex align-items-center" }
const _hoisted_289 = { class: "title ms-15" }
const _hoisted_290 = { class: "d-block mb-7 fs-13 text-uppercase fw-medium text-dark-emphasis" }
const _hoisted_291 = { class: "d-flex align-items-center" }
const _hoisted_292 = {
  class: "w-black mb-0 me-10",
  style: {"padding-right":"17px"}
}
const _hoisted_293 = { class: "fw-black mb-0 me-10" }
const _hoisted_294 = { class: "d-flex align-items-center" }
const _hoisted_295 = { class: "w-black mb-0 me-10" }
const _hoisted_296 = ["innerHTML"]
const _hoisted_297 = { class: "fw-black mb-0 me-10" }
const _hoisted_298 = { class: "col-xl-4 col-sm-6" }
const _hoisted_299 = { class: "card mb-25 border-0 rounded-0 bg-white stats-box shadow-link" }
const _hoisted_300 = { class: "card-body pe-20 ps-20 pe-md-25 ps-md-25 pe-lg-15 ps-lg-15" }
const _hoisted_301 = { class: "d-flex align-items-center" }
const _hoisted_302 = { class: "title ms-15" }
const _hoisted_303 = { class: "d-block mb-7 fs-13 text-uppercase fw-medium text-dark-emphasis" }
const _hoisted_304 = { class: "d-flex align-items-center" }
const _hoisted_305 = {
  class: "w-black mb-0 me-10",
  style: {"padding-right":"17px"}
}
const _hoisted_306 = { class: "fw-black mb-0 me-10" }
const _hoisted_307 = { class: "d-flex align-items-center" }
const _hoisted_308 = { class: "w-black mb-0 me-10" }
const _hoisted_309 = ["innerHTML"]
const _hoisted_310 = { class: "fw-black mb-0 me-10" }
const _hoisted_311 = { key: 1 }
const _hoisted_312 = { class: "row" }
const _hoisted_313 = { class: "col-xxl-12 col-sm-12" }
const _hoisted_314 = { class: "card mb-20 border-0 rounded-0 stats-item bg-white" }
const _hoisted_315 = { class: "card-body p-0 p-sm-0 p-md-0 p-lg-0 letter-spacing" }
const _hoisted_316 = { class: "d-flex align-items-center" }
const _hoisted_317 = { class: "d-block text-muted fs-12 fw-medium p-2 p-sm-2 p-md-2 p-lg-2" }
const _hoisted_318 = { class: "mb-4 mb-sm-0" }
const _hoisted_319 = { key: 2 }
const _hoisted_320 = { class: "mb-20 d-sm-flex align-items-center justify-content-between letter-spacing" }
const _hoisted_321 = { key: 3 }
const _hoisted_322 = { class: "mb-20 d-sm-flex align-items-center justify-content-between letter-spacing" }
const _hoisted_323 = { key: 4 }
const _hoisted_324 = {
  key: 0,
  class: "mb-20 d-sm-flex align-items-center justify-content-between letter-spacing"
}
const _hoisted_325 = { key: 5 }
const _hoisted_326 = {
  key: 0,
  class: "mb-20 d-sm-flex align-items-center justify-content-between letter-spacing"
}
const _hoisted_327 = { key: 6 }
const _hoisted_328 = {
  key: 0,
  class: "mb-20 d-sm-flex align-items-center justify-content-between letter-spacing"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FootSpaceDisplacementGraph = _resolveComponent("FootSpaceDisplacementGraph")!
  const _component_SignalGraphKinematics = _resolveComponent("SignalGraphKinematics")!
  const _component_PerformanceGraph = _resolveComponent("PerformanceGraph")!
  const _component_KinematicsGraphDinamic = _resolveComponent("KinematicsGraphDinamic")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.patient.demographic)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (!_ctx.verifyGraph && !_ctx.verifyGraphKine && !_ctx.verifyGrapFootDisplacement)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                (_ctx.patient.demographic)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                      _createElementVNode("div", _hoisted_4, [
                        _createElementVNode("div", _hoisted_5, [
                          _createElementVNode("div", _hoisted_6, [
                            (_ctx.loadEnable)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                                  (_ctx.loadEnable)
                                    ? (_openBlock(), _createElementBlock("img", {
                                        key: 0,
                                        style: {"width":"45px"},
                                        src: _ctx.iconLoading,
                                        alt: "Loading"
                                      }, null, 8, _hoisted_8))
                                    : _createCommentVNode("", true)
                                ]))
                              : (_openBlock(), _createElementBlock("div", _hoisted_9, [
                                  _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.patient.demographic.name), 1),
                                  _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.Lang('patient_genre')) + ": " + _toDisplayString(_ctx.patient.demographic.gender), 1),
                                  _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.Lang('patient_age')) + ": " + _toDisplayString(_ctx.calculateAge(_ctx.patient.demographic.birthday)) + " anos", 1),
                                  _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.Lang('patient_mass')) + ": " + _toDisplayString(_ctx.patient.demographic.weight) + " kg", 1),
                                  _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.Lang('patient_stature')) + ": " + _toDisplayString(_ctx.patient.demographic.height) + " cm", 1),
                                  _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.Lang('patient_preference_up')) + ": " + _toDisplayString((_ctx.patient.demographic.upper_limb === "R")? 'Direita':'Esquerda'), 1),
                                  _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.Lang('patient_preference_down')) + ": " + _toDisplayString((_ctx.patient.demographic.lower_limb === "R")? 'Direita': 'Esquerda'), 1),
                                  _createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.Lang('patient_injury')) + ": " + _toDisplayString(_ctx.patient.demographic.current_injury), 1)
                                ]))
                          ])
                        ])
                      ])
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_18, [
                  _createElementVNode("h4", _hoisted_19, _toDisplayString(_ctx.Lang('subtitle_Countermovement_Jump')), 1)
                ]),
                _createElementVNode("div", _hoisted_20, [
                  _createElementVNode("h6", _hoisted_21, _toDisplayString(_ctx.Lang('titleH1')), 1)
                ]),
                _createElementVNode("div", _hoisted_22, [
                  _createElementVNode("div", _hoisted_23, [
                    _createElementVNode("a", {
                      href: "#/jump_heights_max-graph",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.loadGraph('jump_heights_max', true))),
                      class: "link-graph"
                    }, [
                      _createElementVNode("div", _hoisted_24, [
                        _createElementVNode("div", _hoisted_25, [
                          _createElementVNode("div", _hoisted_26, [
                            _createElementVNode("div", _hoisted_27, [
                              _createElementVNode("span", _hoisted_28, _toDisplayString(_ctx.Lang('performance','Maximum_Height_cm')), 1),
                              _createElementVNode("div", _hoisted_29, [
                                _createElementVNode("h3", _hoisted_30, _toDisplayString(_ctx.jump_heights_max_current), 1),
                                (!(_ctx.jump_heights_max_perc_variation === 0))
                                  ? (_openBlock(), _createElementBlock("div", {
                                      key: 0,
                                      innerHTML: _ctx.loadingPercentage(_ctx.jump_heights_max_perc_variation)
                                    }, null, 8, _hoisted_31))
                                  : _createCommentVNode("", true)
                              ])
                            ])
                          ])
                        ])
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_32, [
                    _createElementVNode("a", {
                      href: "#/jump_heights_mean-graph",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.loadGraph('jump_heights_mean', true))),
                      class: "link-graph"
                    }, [
                      _createElementVNode("div", _hoisted_33, [
                        _createElementVNode("div", _hoisted_34, [
                          _createElementVNode("div", _hoisted_35, [
                            _createElementVNode("div", _hoisted_36, [
                              _createElementVNode("span", _hoisted_37, _toDisplayString(_ctx.Lang('performance', 'Average_Height_cm')), 1),
                              _createElementVNode("div", _hoisted_38, [
                                _createElementVNode("h3", _hoisted_39, _toDisplayString(_ctx.jump_heights_mean_current), 1),
                                (!(_ctx.jump_heights_mean_current_perc_variation === 0))
                                  ? (_openBlock(), _createElementBlock("div", {
                                      key: 0,
                                      innerHTML: _ctx.loadingPercentage(_ctx.jump_heights_mean_current_perc_variation)
                                    }, null, 8, _hoisted_40))
                                  : _createCommentVNode("", true)
                              ])
                            ])
                          ])
                        ])
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_41, [
                    _createElementVNode("a", {
                      href: "#/peak_powers_max-graph",
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.loadGraph('peak_powers_max', true))),
                      class: "link-graph"
                    }, [
                      _createElementVNode("div", _hoisted_42, [
                        _createElementVNode("div", _hoisted_43, [
                          _createElementVNode("div", _hoisted_44, [
                            _createElementVNode("div", _hoisted_45, [
                              _createElementVNode("span", _hoisted_46, _toDisplayString(_ctx.Lang('performance','PEAK_POWER_W_Kg')), 1),
                              _createElementVNode("div", _hoisted_47, [
                                _createElementVNode("h3", _hoisted_48, _toDisplayString(_ctx.peak_powers_max), 1),
                                (!(_ctx.peak_powers_max_perc_variation === 0))
                                  ? (_openBlock(), _createElementBlock("div", {
                                      key: 0,
                                      innerHTML: _ctx.loadingPercentage(_ctx.peak_powers_max_perc_variation)
                                    }, null, 8, _hoisted_49))
                                  : _createCommentVNode("", true)
                              ])
                            ])
                          ])
                        ])
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_50, [
                    _createElementVNode("a", {
                      href: "#/peak_powers_mean-graph",
                      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.loadGraph('peak_powers_mean', true))),
                      class: "link-graph"
                    }, [
                      _createElementVNode("div", _hoisted_51, [
                        _createElementVNode("div", _hoisted_52, [
                          _createElementVNode("div", _hoisted_53, [
                            _createElementVNode("div", _hoisted_54, [
                              _createElementVNode("span", _hoisted_55, _toDisplayString(_ctx.Lang('performance','Average_Peak_Power_W_Kg')), 1),
                              _createElementVNode("div", _hoisted_56, [
                                _createElementVNode("h3", _hoisted_57, _toDisplayString(_ctx.peak_powers_mean), 1),
                                (!(_ctx.peak_powers_mean_perc_variation === 0))
                                  ? (_openBlock(), _createElementBlock("div", {
                                      key: 0,
                                      innerHTML: _ctx.loadingPercentage(_ctx.peak_powers_mean_perc_variation)
                                    }, null, 8, _hoisted_58))
                                  : _createCommentVNode("", true)
                              ])
                            ])
                          ])
                        ])
                      ])
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_59, [
                  _createElementVNode("div", _hoisted_60, [
                    _createElementVNode("a", {
                      href: "#/adjusted_RSIs_max-graph",
                      onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.loadGraph('adjusted_RSIs_max', true))),
                      class: "link-graph"
                    }, [
                      _createElementVNode("div", _hoisted_61, [
                        _createElementVNode("div", _hoisted_62, [
                          _createElementVNode("div", _hoisted_63, [
                            _createElementVNode("div", _hoisted_64, [
                              _createElementVNode("span", _hoisted_65, _toDisplayString(_ctx.Lang('performance', 'Reactive_Strength_Index_RSI')), 1),
                              _createElementVNode("div", _hoisted_66, [
                                _createElementVNode("h3", _hoisted_67, _toDisplayString(_ctx.adjusted_RSIs_max_current), 1),
                                (!(_ctx.adjusted_RSIs_max_perc_variation === 0))
                                  ? (_openBlock(), _createElementBlock("div", {
                                      key: 0,
                                      innerHTML: _ctx.loadingPercentage(_ctx.adjusted_RSIs_max_perc_variation)
                                    }, null, 8, _hoisted_68))
                                  : _createCommentVNode("", true)
                              ])
                            ])
                          ])
                        ])
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_69, [
                    _createElementVNode("a", {
                      href: "#/adjusted_RSIs_mean-graph",
                      onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.loadGraph('adjusted_RSIs_mean', true))),
                      class: "link-graph"
                    }, [
                      _createElementVNode("div", _hoisted_70, [
                        _createElementVNode("div", _hoisted_71, [
                          _createElementVNode("div", _hoisted_72, [
                            _createElementVNode("div", _hoisted_73, [
                              _createElementVNode("span", _hoisted_74, _toDisplayString(_ctx.Lang('performance','adjusted_RSIs_mean_RSI')), 1),
                              _createElementVNode("div", _hoisted_75, [
                                _createElementVNode("h3", _hoisted_76, _toDisplayString(_ctx.adjusted_RSIs_mean_current), 1),
                                (!(_ctx.adjusted_RSIs_mean_perc_variation === 0))
                                  ? (_openBlock(), _createElementBlock("div", {
                                      key: 0,
                                      innerHTML: _ctx.loadingPercentage(_ctx.adjusted_RSIs_mean_perc_variation)
                                    }, null, 8, _hoisted_77))
                                  : _createCommentVNode("", true)
                              ])
                            ])
                          ])
                        ])
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_78, [
                    _createElementVNode("a", {
                      href: "#/deslocamento-horizontal",
                      onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.loadGraphFootSpaceDisplacement('body_displacemente', true))),
                      class: "link-graph"
                    }, [
                      _createElementVNode("div", _hoisted_79, [
                        _createElementVNode("div", _hoisted_80, [
                          _createElementVNode("div", _hoisted_81, [
                            _createElementVNode("div", _hoisted_82, [
                              _createElementVNode("span", _hoisted_83, _toDisplayString(_ctx.Lang('performance','HORIZONTAL_OFFSET_CM')), 1),
                              _createElementVNode("div", _hoisted_84, [
                                _createElementVNode("h3", _hoisted_85, _toDisplayString(_ctx.body_displacemente_current), 1),
                                (!(_ctx.body_displacemente_perc_variation === 0))
                                  ? (_openBlock(), _createElementBlock("div", {
                                      key: 0,
                                      innerHTML: _ctx.loadingPercentage(_ctx.body_displacemente_perc_variation)
                                    }, null, 8, _hoisted_86))
                                  : _createCommentVNode("", true)
                              ])
                            ])
                          ])
                        ])
                      ])
                    ])
                  ])
                ]),
                _createElementVNode("p", null, [
                  _createElementVNode("small", null, _toDisplayString(_ctx.Lang('date_Last_review_on')) + " " + _toDisplayString(_ctx.showDateLast()), 1)
                ]),
                _createElementVNode("div", _hoisted_87, [
                  _createElementVNode("h4", _hoisted_88, _toDisplayString(_ctx.Lang('kinematic_box','titleH1')), 1)
                ]),
                _createElementVNode("div", _hoisted_89, [
                  _createElementVNode("h6", _hoisted_90, _toDisplayString(_ctx.Lang('kinematic_box', 'subtitle_1')), 1)
                ]),
                _createElementVNode("div", _hoisted_91, [
                  _createElementVNode("div", _hoisted_92, [
                    _createElementVNode("div", _hoisted_93, [
                      _createElementVNode("a", {
                        href: "#/kinematics-graph",
                        onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.loadGraphKine('Hip', 'Ext_Flex', true))),
                        class: "link-graph"
                      }, [
                        _createElementVNode("div", _hoisted_94, [
                          _createElementVNode("div", _hoisted_95, [
                            _createElementVNode("div", _hoisted_96, [
                              _createElementVNode("span", _hoisted_97, _toDisplayString(_ctx.Lang('kinematic_box','Hip_Flexion_Extension')), 1),
                              _createElementVNode("div", _hoisted_98, [
                                _createElementVNode("p", _hoisted_99, _toDisplayString(_ctx.Lang('text_Right')), 1),
                                _createElementVNode("h3", _hoisted_100, _toDisplayString(_ctx.kinematic_Hip_Ext_Flex_R_propulsive_current) + "°", 1)
                              ]),
                              _createElementVNode("div", _hoisted_101, [
                                _createElementVNode("p", _hoisted_102, [
                                  _createTextVNode(_toDisplayString(_ctx.Lang('text_Left')), 1),
                                  _createElementVNode("span", {
                                    innerHTML: _ctx.showSpaceLeft()
                                  }, null, 8, _hoisted_103)
                                ]),
                                _createElementVNode("h3", _hoisted_104, _toDisplayString(_ctx.Hip_Ext_Flex_L_propulsive_current) + "°", 1)
                              ])
                            ])
                          ])
                        ])
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_105, [
                    _createElementVNode("div", _hoisted_106, [
                      _createElementVNode("a", {
                        href: "#/kinematics-graph",
                        onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.loadGraphKine('Hip', 'Abd_Add', true))),
                        class: "link-graph"
                      }, [
                        _createElementVNode("div", _hoisted_107, [
                          _createElementVNode("div", _hoisted_108, [
                            _createElementVNode("div", _hoisted_109, [
                              _createElementVNode("span", _hoisted_110, _toDisplayString(_ctx.Lang('kinematic_box', 'Hip_Adduction_Abduction')), 1),
                              _createElementVNode("div", _hoisted_111, [
                                _createElementVNode("p", _hoisted_112, _toDisplayString(_ctx.Lang('text_Right')), 1),
                                _createElementVNode("h3", _hoisted_113, _toDisplayString(_ctx.Hip_Abd_Add_R_propulsive_current) + "°", 1)
                              ]),
                              _createElementVNode("div", _hoisted_114, [
                                _createElementVNode("p", _hoisted_115, [
                                  _createTextVNode(_toDisplayString(_ctx.Lang('text_Left')), 1),
                                  _createElementVNode("span", {
                                    innerHTML: _ctx.showSpaceLeft()
                                  }, null, 8, _hoisted_116)
                                ]),
                                _createElementVNode("h3", _hoisted_117, _toDisplayString(_ctx.Hip_Abd_Add_L_propulsive_current) + "°", 1)
                              ])
                            ])
                          ])
                        ])
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_118, [
                    _createElementVNode("div", _hoisted_119, [
                      _createElementVNode("a", {
                        href: "#/kinematics-graph",
                        onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.loadGraphKine('Hip', 'RotM_RotL', true))),
                        class: "link-graph"
                      }, [
                        _createElementVNode("div", _hoisted_120, [
                          _createElementVNode("div", _hoisted_121, [
                            _createElementVNode("div", _hoisted_122, [
                              _createElementVNode("span", _hoisted_123, _toDisplayString(_ctx.Lang('kinematic_box', 'Hip_Quadril_Rot_Medial_Rot_Lateral')), 1),
                              _createElementVNode("div", _hoisted_124, [
                                _createElementVNode("p", _hoisted_125, _toDisplayString(_ctx.Lang('text_Right')), 1),
                                _createElementVNode("h3", _hoisted_126, _toDisplayString(_ctx.Hip_RotM_RotL_R_propulsive_current) + "°", 1)
                              ]),
                              _createElementVNode("div", _hoisted_127, [
                                _createElementVNode("p", _hoisted_128, [
                                  _createTextVNode(_toDisplayString(_ctx.Lang('text_Left')), 1),
                                  _createElementVNode("span", {
                                    innerHTML: _ctx.showSpaceLeft()
                                  }, null, 8, _hoisted_129)
                                ]),
                                _createElementVNode("h3", _hoisted_130, _toDisplayString(_ctx.Hip_RotM_RotL_L_propulsive_current) + "°", 1)
                              ])
                            ])
                          ])
                        ])
                      ])
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_131, [
                  _createElementVNode("div", _hoisted_132, [
                    _createElementVNode("div", _hoisted_133, [
                      _createElementVNode("div", _hoisted_134, [
                        _createElementVNode("div", _hoisted_135, [
                          _createElementVNode("div", _hoisted_136, [
                            _createElementVNode("a", {
                              href: "#/kinematics-graph-Ext_Flex",
                              onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.loadGraphKine('Knee', 'Ext_Flex', true))),
                              class: "link-graph"
                            }, [
                              _createElementVNode("span", _hoisted_137, _toDisplayString(_ctx.Lang('kinematic_box','Knee_Flexion_Extension')), 1),
                              _createElementVNode("div", _hoisted_138, [
                                _createElementVNode("p", _hoisted_139, _toDisplayString(_ctx.Lang('text_Right')), 1),
                                _createElementVNode("h3", _hoisted_140, _toDisplayString(_ctx.Knee_Ext_Flex_R_propulsive_current) + "°", 1)
                              ]),
                              _createElementVNode("div", _hoisted_141, [
                                _createElementVNode("p", _hoisted_142, [
                                  _createTextVNode(_toDisplayString(_ctx.Lang('text_Left')), 1),
                                  _createElementVNode("span", {
                                    innerHTML: _ctx.showSpaceLeft()
                                  }, null, 8, _hoisted_143)
                                ]),
                                _createElementVNode("h3", _hoisted_144, _toDisplayString(_ctx.Knee_Ext_Flex_L_propulsive_value) + "°", 1)
                              ])
                            ])
                          ])
                        ])
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_145, [
                    _createElementVNode("div", _hoisted_146, [
                      _createElementVNode("div", _hoisted_147, [
                        _createElementVNode("div", _hoisted_148, [
                          _createElementVNode("div", _hoisted_149, [
                            _createElementVNode("a", {
                              href: "#/kinematics-graph-Varus_Valgus",
                              onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.loadGraphKine('Knee', 'Varus_Valgus', true))),
                              class: "link-graph"
                            }, [
                              _createElementVNode("span", _hoisted_150, _toDisplayString(_ctx.Lang('kinematic_box','Knee_Varus_Valgus')), 1),
                              _createElementVNode("div", _hoisted_151, [
                                _createElementVNode("p", _hoisted_152, _toDisplayString(_ctx.Lang('text_Right')), 1),
                                _createElementVNode("h3", _hoisted_153, _toDisplayString(_ctx.Knee_Varus_Valgus_R_propulsive_value) + "°", 1)
                              ]),
                              _createElementVNode("div", _hoisted_154, [
                                _createElementVNode("p", _hoisted_155, [
                                  _createTextVNode(_toDisplayString(_ctx.Lang('text_Left')), 1),
                                  _createElementVNode("span", {
                                    innerHTML: _ctx.showSpaceLeft()
                                  }, null, 8, _hoisted_156)
                                ]),
                                _createElementVNode("h3", _hoisted_157, _toDisplayString(_ctx.Knee_Varus_Valgus_L_propulsive_value) + "°", 1)
                              ])
                            ])
                          ])
                        ])
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_158, [
                    _createElementVNode("div", _hoisted_159, [
                      _createElementVNode("div", _hoisted_160, [
                        _createElementVNode("div", _hoisted_161, [
                          _createElementVNode("div", _hoisted_162, [
                            _createElementVNode("a", {
                              href: "#/kinematics-graph-Valgus_count",
                              onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.loadGraphKine('Knee', 'Valgus_count', true))),
                              class: "link-graph"
                            }, [
                              _createElementVNode("span", _hoisted_163, _toDisplayString(_ctx.Lang('kinematic_box','Knee_Valgus')), 1),
                              _createElementVNode("div", _hoisted_164, [
                                _createElementVNode("p", _hoisted_165, _toDisplayString(_ctx.Lang('text_Right')), 1),
                                _createElementVNode("h3", _hoisted_166, _toDisplayString(_ctx.Knee_Valgus_count_R_value), 1),
                                (_ctx.Knee_Valgus_count_R_percentage != 0 && !_ctx.isNull(_ctx.Knee_Valgus_count_R_percentage))
                                  ? (_openBlock(), _createElementBlock("div", {
                                      key: 0,
                                      innerHTML: _ctx.loadingPercentage(_ctx.Knee_Valgus_count_R_percentage)
                                    }, null, 8, _hoisted_167))
                                  : _createCommentVNode("", true)
                              ]),
                              _createElementVNode("div", _hoisted_168, [
                                _createElementVNode("p", _hoisted_169, [
                                  _createTextVNode(_toDisplayString(_ctx.Lang('text_Left')), 1),
                                  _createElementVNode("span", {
                                    innerHTML: _ctx.showSpaceLeft()
                                  }, null, 8, _hoisted_170)
                                ]),
                                _createElementVNode("h3", _hoisted_171, _toDisplayString(_ctx.Knee_Valgus_count_L_value), 1),
                                (_ctx.Knee_Valgus_count_R_percentage != 0 && !_ctx.isNull(_ctx.Knee_Valgus_count_L_percentage))
                                  ? (_openBlock(), _createElementBlock("div", {
                                      key: 0,
                                      innerHTML: _ctx.loadingPercentage(_ctx.Knee_Valgus_count_L_percentage)
                                    }, null, 8, _hoisted_172))
                                  : _createCommentVNode("", true)
                              ])
                            ])
                          ])
                        ])
                      ])
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_173, [
                  _createElementVNode("div", _hoisted_174, [
                    _createElementVNode("div", _hoisted_175, [
                      _createElementVNode("div", _hoisted_176, [
                        _createElementVNode("div", _hoisted_177, [
                          _createElementVNode("div", _hoisted_178, [
                            _createElementVNode("a", {
                              href: "#/kinematics-graph-Ankle",
                              onClick: _cache[13] || (_cache[13] = ($event: any) => (_ctx.loadGraphKine('Ankle', 'Dorsi_PlantarFlex', true))),
                              class: "link-graph"
                            }, [
                              _createElementVNode("span", _hoisted_179, _toDisplayString(_ctx.Lang('kinematic_box','Ankle_Dorsiflexion_Plantar_Flexion')), 1),
                              _createElementVNode("div", _hoisted_180, [
                                _createElementVNode("p", _hoisted_181, _toDisplayString(_ctx.Lang('text_Right')), 1),
                                _createElementVNode("h3", _hoisted_182, _toDisplayString(_ctx.Ankle_Dorsi_PlantarFlex_R_value) + "°", 1)
                              ]),
                              _createElementVNode("div", _hoisted_183, [
                                _createElementVNode("p", _hoisted_184, [
                                  _createTextVNode(_toDisplayString(_ctx.Lang('text_Left')), 1),
                                  _createElementVNode("span", {
                                    innerHTML: _ctx.showSpaceLeft()
                                  }, null, 8, _hoisted_185)
                                ]),
                                _createElementVNode("h3", _hoisted_186, _toDisplayString(_ctx.Ankle_Dorsi_PlantarFlex_L_value) + "°", 1)
                              ])
                            ])
                          ])
                        ])
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_187, [
                    _createElementVNode("div", _hoisted_188, [
                      _createElementVNode("div", _hoisted_189, [
                        _createElementVNode("div", _hoisted_190, [
                          _createElementVNode("div", _hoisted_191, [
                            _createElementVNode("a", {
                              href: "#/kinematics-graph-Inv_Ever",
                              onClick: _cache[14] || (_cache[14] = ($event: any) => (_ctx.loadGraphKine('Ankle', 'Inv_Ever', true))),
                              class: "link-graph"
                            }, [
                              _createElementVNode("span", _hoisted_192, _toDisplayString(_ctx.Lang('kinematic_box','Ankle_Inversion_Eversion')), 1),
                              _createElementVNode("div", _hoisted_193, [
                                _createElementVNode("p", _hoisted_194, _toDisplayString(_ctx.Lang('text_Right')), 1),
                                _createElementVNode("h3", _hoisted_195, _toDisplayString(_ctx.Ankle_Inv_Ever_R_value) + "°", 1)
                              ]),
                              _createElementVNode("div", _hoisted_196, [
                                _createElementVNode("p", _hoisted_197, [
                                  _createTextVNode(_toDisplayString(_ctx.Lang('text_Left')), 1),
                                  _createElementVNode("span", {
                                    innerHTML: _ctx.showSpaceLeft()
                                  }, null, 8, _hoisted_198)
                                ]),
                                _createElementVNode("h3", _hoisted_199, _toDisplayString(_ctx.Ankle_Inv_Ever_L_value) + "°", 1)
                              ])
                            ])
                          ])
                        ])
                      ])
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_200, [
                  _createElementVNode("h6", _hoisted_201, _toDisplayString(_ctx.Lang('recovery_box', 'subtitle_1')), 1)
                ]),
                _createElementVNode("div", _hoisted_202, [
                  _createElementVNode("div", _hoisted_203, [
                    _createElementVNode("div", _hoisted_204, [
                      _createElementVNode("a", {
                        href: "#/kinematics-graph-Ext_Flex-Recovery",
                        onClick: _cache[15] || (_cache[15] = ($event: any) => (_ctx.loadGraphKineRecovery('Hip', 'Ext_Flex', true))),
                        class: "link-graph"
                      }, [
                        _createElementVNode("div", _hoisted_205, [
                          _createElementVNode("div", _hoisted_206, [
                            _createElementVNode("div", _hoisted_207, [
                              _createElementVNode("span", _hoisted_208, _toDisplayString(_ctx.Lang('recovery_box', 'Hip_Flexion_Extension')), 1),
                              _createElementVNode("div", _hoisted_209, [
                                _createElementVNode("p", _hoisted_210, _toDisplayString(_ctx.Lang('text_Right')), 1),
                                _createElementVNode("h3", _hoisted_211, _toDisplayString(_ctx.kinematic_Hip_Ext_Flex_R_recovery) + "°", 1)
                              ]),
                              _createElementVNode("div", _hoisted_212, [
                                _createElementVNode("p", _hoisted_213, [
                                  _createTextVNode(_toDisplayString(_ctx.Lang('text_Left')), 1),
                                  _createElementVNode("span", {
                                    innerHTML: _ctx.showSpaceLeft()
                                  }, null, 8, _hoisted_214)
                                ]),
                                _createElementVNode("h3", _hoisted_215, _toDisplayString(_ctx.kinematic_Hip_Ext_Flex_L_recovery) + "°", 1)
                              ])
                            ])
                          ])
                        ])
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_216, [
                    _createElementVNode("div", _hoisted_217, [
                      _createElementVNode("a", {
                        href: "#/kinematics-graph-Abd_Add-Recovery",
                        onClick: _cache[16] || (_cache[16] = ($event: any) => (_ctx.loadGraphKineRecovery('Hip', 'Abd_Add', true))),
                        class: "link-graph"
                      }, [
                        _createElementVNode("div", _hoisted_218, [
                          _createElementVNode("div", _hoisted_219, [
                            _createElementVNode("div", _hoisted_220, [
                              _createElementVNode("span", _hoisted_221, _toDisplayString(_ctx.Lang('recovery_box','Hip_Adduction_Abduction')), 1),
                              _createElementVNode("div", _hoisted_222, [
                                _createElementVNode("p", _hoisted_223, _toDisplayString(_ctx.Lang('text_Right')), 1),
                                _createElementVNode("h3", _hoisted_224, _toDisplayString(_ctx.Abd_Add_R_recovery) + "°", 1)
                              ]),
                              _createElementVNode("div", _hoisted_225, [
                                _createElementVNode("p", _hoisted_226, [
                                  _createTextVNode(_toDisplayString(_ctx.Lang('text_Left')), 1),
                                  _createElementVNode("span", {
                                    innerHTML: _ctx.showSpaceLeft()
                                  }, null, 8, _hoisted_227)
                                ]),
                                _createElementVNode("h3", _hoisted_228, _toDisplayString(_ctx.Abd_Add_L_recovery) + "°", 1)
                              ])
                            ])
                          ])
                        ])
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_229, [
                    _createElementVNode("div", _hoisted_230, [
                      _createElementVNode("a", {
                        href: "#/kinematics-graph-RotM_RotL-Recovery",
                        onClick: _cache[17] || (_cache[17] = ($event: any) => (_ctx.loadGraphKineRecovery('Hip', 'RotM_RotL', true))),
                        class: "link-graph"
                      }, [
                        _createElementVNode("div", _hoisted_231, [
                          _createElementVNode("div", _hoisted_232, [
                            _createElementVNode("div", _hoisted_233, [
                              _createElementVNode("span", _hoisted_234, _toDisplayString(_ctx.Lang('recovery_box', 'Hip_Quadril_Rot_Medial_Rot_Lateral')), 1),
                              _createElementVNode("div", _hoisted_235, [
                                _createElementVNode("p", _hoisted_236, _toDisplayString(_ctx.Lang('text_Right')), 1),
                                _createElementVNode("h3", _hoisted_237, _toDisplayString(_ctx.RotM_RotL_R_recovery) + "°", 1)
                              ]),
                              _createElementVNode("div", _hoisted_238, [
                                _createElementVNode("p", _hoisted_239, [
                                  _createTextVNode(_toDisplayString(_ctx.Lang('text_Left')), 1),
                                  _createElementVNode("span", {
                                    innerHTML: _ctx.showSpaceLeft()
                                  }, null, 8, _hoisted_240)
                                ]),
                                _createElementVNode("h3", _hoisted_241, _toDisplayString(_ctx.RotM_RotL_L_recovery) + "°", 1)
                              ])
                            ])
                          ])
                        ])
                      ])
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_242, [
                  _createElementVNode("div", _hoisted_243, [
                    _createElementVNode("div", _hoisted_244, [
                      _createElementVNode("a", {
                        href: "#/kinematics-graph-Knee_Ext_Flex-Recovery",
                        onClick: _cache[18] || (_cache[18] = ($event: any) => (_ctx.loadGraphKineRecovery('Knee', 'Ext_Flex', true))),
                        class: "link-graph"
                      }, [
                        _createElementVNode("div", _hoisted_245, [
                          _createElementVNode("div", _hoisted_246, [
                            _createElementVNode("div", _hoisted_247, [
                              _createElementVNode("span", _hoisted_248, _toDisplayString(_ctx.Lang('recovery_box', 'Knee_Flexion_Extension')), 1),
                              _createElementVNode("div", _hoisted_249, [
                                _createElementVNode("p", _hoisted_250, _toDisplayString(_ctx.Lang('text_Right')), 1),
                                _createElementVNode("h3", _hoisted_251, _toDisplayString(_ctx.Knee_Ext_Flex_R_recovery) + "°", 1)
                              ]),
                              _createElementVNode("div", _hoisted_252, [
                                _createElementVNode("p", _hoisted_253, [
                                  _createTextVNode(_toDisplayString(_ctx.Lang('text_Left')), 1),
                                  _createElementVNode("span", {
                                    innerHTML: _ctx.showSpaceLeft()
                                  }, null, 8, _hoisted_254)
                                ]),
                                _createElementVNode("h3", _hoisted_255, _toDisplayString(_ctx.Knee_Ext_Flex_L_recovery) + "°", 1)
                              ])
                            ])
                          ])
                        ])
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_256, [
                    _createElementVNode("div", _hoisted_257, [
                      _createElementVNode("a", {
                        href: "#/kinematics-graph-Knee-Varus_Valgus-Recovery",
                        onClick: _cache[19] || (_cache[19] = ($event: any) => (_ctx.loadGraphKineRecovery('Knee', 'Varus_Valgus', true))),
                        class: "link-graph"
                      }, [
                        _createElementVNode("div", _hoisted_258, [
                          _createElementVNode("div", _hoisted_259, [
                            _createElementVNode("div", _hoisted_260, [
                              _createElementVNode("span", _hoisted_261, _toDisplayString(_ctx.Lang('recovery_box', 'Knee_Varus_Valgus')), 1),
                              _createElementVNode("div", _hoisted_262, [
                                _createElementVNode("p", _hoisted_263, _toDisplayString(_ctx.Lang('text_Right')), 1),
                                _createElementVNode("h3", _hoisted_264, _toDisplayString(_ctx.Knee_Varus_Valgus_R_recovery) + "°", 1)
                              ]),
                              _createElementVNode("div", _hoisted_265, [
                                _createElementVNode("p", _hoisted_266, [
                                  _createTextVNode(_toDisplayString(_ctx.Lang('text_Left')), 1),
                                  _createElementVNode("span", {
                                    innerHTML: _ctx.showSpaceLeft()
                                  }, null, 8, _hoisted_267)
                                ]),
                                _createElementVNode("h3", _hoisted_268, _toDisplayString(_ctx.Knee_Varus_Valgus_L_recovery) + "°", 1)
                              ])
                            ])
                          ])
                        ])
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_269, [
                    _createElementVNode("div", _hoisted_270, [
                      _createElementVNode("a", {
                        href: "#/kinematics-graph-Knee-Valgus_count-Recovery",
                        onClick: _cache[20] || (_cache[20] = ($event: any) => (_ctx.loadGraphKineRecovery('Knee', 'Valgus_count', true))),
                        class: "link-graph"
                      }, [
                        _createElementVNode("div", _hoisted_271, [
                          _createElementVNode("div", _hoisted_272, [
                            _createElementVNode("div", _hoisted_273, [
                              _createElementVNode("span", _hoisted_274, _toDisplayString(_ctx.Lang('recovery_box', 'Knee_Valgus')), 1),
                              _createElementVNode("div", _hoisted_275, [
                                _createElementVNode("p", _hoisted_276, _toDisplayString(_ctx.Lang('text_Right')), 1),
                                _createElementVNode("h3", _hoisted_277, _toDisplayString(_ctx.Knee_Valgus_count_R_recovery), 1),
                                (!_ctx.isNull(_ctx.Knee_Valgus_count_R_recovery_percentual))
                                  ? (_openBlock(), _createElementBlock("div", {
                                      key: 0,
                                      innerHTML: _ctx.loadingPercentage(_ctx.Knee_Valgus_count_R_recovery_percentual)
                                    }, null, 8, _hoisted_278))
                                  : _createCommentVNode("", true)
                              ]),
                              _createElementVNode("div", _hoisted_279, [
                                _createElementVNode("p", _hoisted_280, [
                                  _createTextVNode(_toDisplayString(_ctx.Lang('text_Left')), 1),
                                  _createElementVNode("span", {
                                    innerHTML: _ctx.showSpaceLeft()
                                  }, null, 8, _hoisted_281)
                                ]),
                                _createElementVNode("h3", _hoisted_282, _toDisplayString(_ctx.Knee_Valgus_count_L_recovery), 1),
                                (!_ctx.isNull(_ctx.Knee_Valgus_count_L_recovery_percentual))
                                  ? (_openBlock(), _createElementBlock("div", {
                                      key: 0,
                                      innerHTML: _ctx.loadingPercentage(_ctx.Knee_Valgus_count_L_recovery_percentual)
                                    }, null, 8, _hoisted_283))
                                  : _createCommentVNode("", true)
                              ])
                            ])
                          ])
                        ])
                      ])
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_284, [
                  _createElementVNode("div", _hoisted_285, [
                    _createElementVNode("div", _hoisted_286, [
                      _createElementVNode("a", {
                        href: "#/kinematics-graph-Ankle-Dorsi_PlantarFlex-Recovery",
                        onClick: _cache[21] || (_cache[21] = ($event: any) => (_ctx.loadGraphKineRecovery('Ankle', 'Dorsi_PlantarFlex', true))),
                        class: "link-graph"
                      }, [
                        _createElementVNode("div", _hoisted_287, [
                          _createElementVNode("div", _hoisted_288, [
                            _createElementVNode("div", _hoisted_289, [
                              _createElementVNode("span", _hoisted_290, _toDisplayString(_ctx.Lang('recovery_box', 'Ankle_Dorsiflexion_Plantar_Flexion')), 1),
                              _createElementVNode("div", _hoisted_291, [
                                _createElementVNode("p", _hoisted_292, _toDisplayString(_ctx.Lang('text_Right')), 1),
                                _createElementVNode("h3", _hoisted_293, _toDisplayString(_ctx.Ankle_Dorsi_PlantarFlex_R_recovery) + "°", 1)
                              ]),
                              _createElementVNode("div", _hoisted_294, [
                                _createElementVNode("p", _hoisted_295, [
                                  _createTextVNode(_toDisplayString(_ctx.Lang('text_Left')), 1),
                                  _createElementVNode("span", {
                                    innerHTML: _ctx.showSpaceLeft()
                                  }, null, 8, _hoisted_296)
                                ]),
                                _createElementVNode("h3", _hoisted_297, _toDisplayString(_ctx.Ankle_Dorsi_PlantarFlex_L_recovery) + "°", 1)
                              ])
                            ])
                          ])
                        ])
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_298, [
                    _createElementVNode("div", _hoisted_299, [
                      _createElementVNode("a", {
                        href: "#/kinematics-graph-Ankle-Inv_Ever-Recovery",
                        onClick: _cache[22] || (_cache[22] = ($event: any) => (_ctx.loadGraphKineRecovery('Ankle', 'Inv_Ever', true))),
                        class: "link-graph"
                      }, [
                        _createElementVNode("div", _hoisted_300, [
                          _createElementVNode("div", _hoisted_301, [
                            _createElementVNode("div", _hoisted_302, [
                              _createElementVNode("span", _hoisted_303, _toDisplayString(_ctx.Lang('recovery_box','Ankle_Inversion_Eversion')), 1),
                              _createElementVNode("div", _hoisted_304, [
                                _createElementVNode("p", _hoisted_305, _toDisplayString(_ctx.Lang('text_Right')), 1),
                                _createElementVNode("h3", _hoisted_306, _toDisplayString(_ctx.Ankle_Inv_Ever_R_recovery) + "°", 1)
                              ]),
                              _createElementVNode("div", _hoisted_307, [
                                _createElementVNode("p", _hoisted_308, [
                                  _createTextVNode(_toDisplayString(_ctx.Lang('text_Left')), 1),
                                  _createElementVNode("span", {
                                    innerHTML: _ctx.showSpaceLeft()
                                  }, null, 8, _hoisted_309)
                                ]),
                                _createElementVNode("h3", _hoisted_310, _toDisplayString(_ctx.Ankle_Inv_Ever_L_recovery) + "°", 1)
                              ])
                            ])
                          ])
                        ])
                      ])
                    ])
                  ])
                ])
              ]))
            : _createCommentVNode("", true)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_311, [
          _createElementVNode("div", _hoisted_312, [
            _createElementVNode("div", _hoisted_313, [
              _createElementVNode("div", _hoisted_314, [
                _createElementVNode("div", _hoisted_315, [
                  _createElementVNode("div", _hoisted_316, [
                    _createElementVNode("span", _hoisted_317, [
                      _createElementVNode("h4", _hoisted_318, _toDisplayString(_ctx.Lang('alert_not_content')), 1)
                    ])
                  ])
                ])
              ])
            ])
          ])
        ])),
    (_ctx.verifyGrapFootDisplacement)
      ? (_openBlock(), _createElementBlock("div", _hoisted_319, [
          _createElementVNode("div", _hoisted_320, [
            _createElementVNode("h5", {
              class: "mb-5 mb-sm-0 fw-bold",
              style: {"cursor":"pointer"},
              onClick: _cache[23] || (_cache[23] = ($event: any) => (_ctx.returnDash()))
            }, "<< " + _toDisplayString(_ctx.Lang('bt_go_back')), 1)
          ]),
          _createVNode(_component_FootSpaceDisplacementGraph, {
            graphFoot: _ctx.graphObjectFootDisplacement,
            Lang: _ctx.Lang('titleGraphFoot')
          }, null, 8, ["graphFoot", "Lang"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.verifyGraphSignalKine)
      ? (_openBlock(), _createElementBlock("div", _hoisted_321, [
          _createElementVNode("div", _hoisted_322, [
            _createElementVNode("h5", {
              class: "mb-5 mb-sm-0 fw-bold",
              style: {"cursor":"pointer"},
              onClick: _cache[24] || (_cache[24] = ($event: any) => (_ctx.returnDash()))
            }, "<< " + _toDisplayString(_ctx.Lang('bt_go_back')), 1)
          ]),
          _createVNode(_component_SignalGraphKinematics, {
            graph: _ctx.graphObjectSignalKine,
            Lang: _ctx.Lang('titleGraphSignalKine')
          }, null, 8, ["graph", "Lang"])
        ]))
      : (_ctx.verifyGraph && !_ctx.verifyGraphKine && !_ctx.verifyGraphRecovery)
        ? (_openBlock(), _createElementBlock("div", _hoisted_323, [
            (!_ctx.returnPerformance)
              ? (_openBlock(), _createElementBlock("div", _hoisted_324, [
                  _createElementVNode("h5", {
                    class: "mb-5 mb-sm-0 fw-bold",
                    style: {"cursor":"pointer"},
                    onClick: _cache[25] || (_cache[25] = ($event: any) => (_ctx.returnDash()))
                  }, "<< " + _toDisplayString(_ctx.Lang('bt_go_back')), 1)
                ]))
              : _createCommentVNode("", true),
            _createVNode(_component_PerformanceGraph, {
              graph: _ctx.graphObject,
              Lang: _ctx.Lang('titleGraph1')
            }, null, 8, ["graph", "Lang"])
          ]))
        : _createCommentVNode("", true),
    (_ctx.verifyGraphRecovery)
      ? (_openBlock(), _createElementBlock("div", _hoisted_325, [
          (!_ctx.returnRecovery)
            ? (_openBlock(), _createElementBlock("div", _hoisted_326, [
                _createElementVNode("h5", {
                  class: "mb-5 mb-sm-0 fw-bold",
                  style: {"cursor":"pointer"},
                  onClick: _cache[26] || (_cache[26] = ($event: any) => (_ctx.returnDash()))
                }, "<< " + _toDisplayString(_ctx.Lang('bt_go_back')), 1)
              ]))
            : _createCommentVNode("", true),
          _createVNode(_component_KinematicsGraphDinamic, {
            graphObject: _ctx.graphObjectKineRecovery,
            Lang: _ctx.Lang('titleGraphRecovery')
          }, null, 8, ["graphObject", "Lang"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.verifyGraphKine)
      ? (_openBlock(), _createElementBlock("div", _hoisted_327, [
          (!_ctx.returnhKinematics)
            ? (_openBlock(), _createElementBlock("div", _hoisted_328, [
                _createElementVNode("h5", {
                  class: "mb-5 mb-sm-0 fw-bold",
                  style: {"cursor":"pointer"},
                  onClick: _cache[27] || (_cache[27] = ($event: any) => (_ctx.returnDash()))
                }, "<< " + _toDisplayString(_ctx.Lang('bt_go_back')), 1)
              ]))
            : _createCommentVNode("", true),
          _createVNode(_component_KinematicsGraphDinamic, {
            graphObject: _ctx.graphObjectKine,
            Lang: _ctx.Lang('titleGraphKine')
          }, null, 8, ["graphObject", "Lang"])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}