import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-073a346c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "sidebar-sub-menu ps-0 mb-0 list-unstyled",
  id: "menu-patients"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.lists, (list) => {
      return (_openBlock(), _createElementBlock("li", {
        class: "sidebar-sub-menu-item",
        key: list.uuid
      }, [
        _createVNode(_component_router_link, {
          to: '/dashboard?report='+`${list.id}`,
          class: "sidebar-sub-menu-link"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(list.name), 1)
          ]),
          _: 2
        }, 1032, ["to"])
      ]))
    }), 128))
  ]))
}