<template>
  <div
    :class="[
      'sidebar-area position-fixed start-0 top-0 bg-black h-100vh transition',
      { active: stateStoreInstance.open },
    ]"
    id="sidebar-area"
  >
    <div class="logo position-absolute start-0 end-0 top-0 bg-black">
      <router-link
        to="/"
        class="d-flex align-items-center text-white text-decoration-none"
      >
        <img src="../../assets/images/favicon.png?v=2" style="max-width: 31%;" alt="logo-icon" />
        <span class="fw-bold ms-10">Mokapp</span>
      </router-link>
      <div class="border-bottom"></div>
      <button
        class="sidebar-burger-menu position-absolute lh-1 bg-transparent p-0 border-0"
        @click="stateStoreInstance.onChange"
      >
        <i class="ph-duotone ph-caret-double-right"></i>
      </button>
    </div>
    <div class="sidebar-menu">
      <ul
        class="sidebar-navbar-nav ps-0 mb-0 list-unstyled accordion"
        id="sidebarNavAccordion"
      >
        <li
          class="sidebar-nav-item accordion-item bg-transparent border-0 rounded-0"
        >
          <a
            href="/dashboard"
            class="accordion-button rounded-0 shadow-none bg-transparent d-block"
            data-bs-toggle="collapse"
            data-bs-target="#sidebarCollapseOne"
            aria-expanded="true"
            aria-controls="sidebarCollapseOne"
          >
            <i class="flaticon-more-1"></i>
            <span class="title">Dashboard</span>
          </a>
          <div
            id="sidebarCollapseOne"
            class="accordion-collapse collapse show"
            data-bs-parent="#sidebarNavAccordion"
          >
            <div class="accordion-body">
              <ul class="sidebar-sub-menu ps-0 mb-0 list-unstyled">
               <!-- MENU LATERAL DASHBOARD -->
               <li class="sidebar-sub-menu-item"><a href="/dashboard" class="sidebar-sub-menu-link">Dashboard</a></li>
               <!--
                <li class="sidebar-sub-menu-item">
                  <router-link to="/crm-system" class="sidebar-sub-menu-link">
                    CRM System
                    <span class="new-badge fw-medium d-inline-block">Hot</span>
                  </router-link>
                </li>
                -->
              </ul>
            </div>
          </div>
        </li>
       
        <!-- START MENU PATIENTS  -->
        <li
          class="sidebar-nav-item accordion-item bg-transparent border-0 rounded-0"
        >
          <a
            href="#"
            class="accordion-button collapsed rounded-0 shadow-none bg-transparent d-block"
            data-bs-toggle="collapse"
            data-bs-target="#sidebarCollapseSeven"
            aria-expanded="false"
            aria-controls="sidebarCollapseSeven"
            @click="removeHighlightClass()"
          >
            <!-- <i class="flaticon-layer-2"></i> -->
            <i class="flaticon-users-group"></i>
            <span class="title">Pacientes</span>
          </a>
          <div
            id="sidebarCollapseSeven"
            class="accordion-collapse collapse"
            data-bs-parent="#sidebarNavAccordion"
          >
            <div class="accordion-body">
              <!-- COMPONENT MENU PATIENTS LIST -->
               <PatientsMenu />
            </div>
          </div>
        </li>
        <!-- END MENU PATIENTS  -->
  
        <!--
        <li class="sidebar-nav-item">
          <router-link @click="LogoutNow()" to="/logout" class="sidebar-nav-link d-block">
            <i class="flaticon-logout"></i>
            <span class="title">Logout</span>
          </router-link>
        </li>
        -->
      </ul>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import stateStore from "../../utils/store";
import PatientsMenu from "../../components/Dashboard/PatientsMenu/index.vue";

export default defineComponent({
  name: "MainSidebar",
  setup() {
    const stateStoreInstance = stateStore;
    return {
      stateStoreInstance,
    };
  },
  components: {
    PatientsMenu
  },
  methods:{
    removeHighlightClass(): void {
      const classToRemove = 'router-link-active';
      const classToRemoveActive = 'active';
      
      // Seleciona o elemento <ul> pelo ID
      const ulElement = document.getElementById('menu-patients');

      if (ulElement) {
        // Seleciona todos os itens <li> dentro do <ul>
        const listItems = ulElement.querySelectorAll<HTMLLIElement>('li');

        // Percorre cada <li> e remove a classe do <a>
        listItems.forEach(li => {
          const anchor = li.querySelector<HTMLAnchorElement>('a');

          if (anchor && anchor.classList.contains(classToRemove)) {
            anchor.classList.remove(classToRemove);
          }
          if (anchor && anchor.classList.contains(classToRemoveActive)) {
            anchor.classList.remove(classToRemoveActive);
          }
        });
      }
    },
    LogoutNow() {
      if (localStorage.getItem('temp_access')) {
        localStorage.removeItem('temp_access');
        setTimeout(() => {
          window.location.href = '/login';  
        }, 2000);
        
      }
    },
  },
});
</script>